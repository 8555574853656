import React, { useState } from "react";
import { Button, Result, message, Checkbox, Space } from "antd";
import "./Unsubscribe.scss";
import { useParams, useSearchParams } from "react-router-dom";
import { surveyTakerService } from "./SurveyTaker.service";
import Unsubscribe from "../../assets/images/unsubscribe1.gif";

function UnsubscribeHandler() {
  const [loading, setLoading] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const handleUnsubscribeEvent = async (globalOptOut: boolean) => {
    try {
      setLoading(true);
      await surveyTakerService.unsubscribe({ token, globalOptOut});
      setUnsubscribed(true);
      message.success(`Successfully unsubscribed`);
    } catch (error) {
      message.error("Failed to unsubscribe. Please try again.");
      console.error("Unsubscribe error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (checkedValues: string[]) => {
    setSelectedOptions(checkedValues);
  };

  if (unsubscribed) {
    return (
      <Result
        status="success"
        title="Successfully Unsubscribed"
        subTitle="You have been unsubscribed from receiving further communications."
      />
    );
  }

  return (
    <div className="container">
      <div className="inner-container">
        <img src={Unsubscribe} alt="Unsubscribe" className="unsubscribe-image" style={{width:"80px"}}/>
        <h2>Thinking of unsubscribing?</h2>
        <p>May be you're still interested in some other updated? If so, please click on the unsubscribe survey:</p>
        <div className="unsubscribe-button-container">
          <Button 
            className="unsubscribe-button" 
            type="primary" 
            danger 
            loading={loading} 
            onClick={() => handleUnsubscribeEvent(false)}
          >
            Unsubscribe Survey
          </Button>
        </div>
        <a href="#" onClick={(e) => { e.preventDefault(); handleUnsubscribeEvent(true);}} style={{ display: 'block', color: '#666', textDecoration: 'underline', fontSize: '14px'}}>
          Unsubscribe from all emails
        </a>
      </div>
    </div>
  );
}

export default UnsubscribeHandler;
