import jwt_decode, { JwtPayload } from "jwt-decode";
import { ISurveyTokenData } from "../components/SurveyTaker/types/survey.types";

export const addScript = (src: string, id: string = String(Date.now())): void => {
  const script = document.createElement('script');
  script.setAttribute('id', id);
  script.setAttribute('src', src);
  document.body.appendChild(script);
}

export const isScriptPresent = (id: string): boolean => !!document?.querySelector(`#${id}`);

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const decodeSurveyTokenForDistributionMode = (token: string): string => {
  try {
    const decodedToken = jwt_decode<ISurveyTokenData>(token);
    return decodedToken?.survey?.distributionMode;
  } catch (error) {
    console.error('Error decoding survey token:', error);
    throw new Error('Invalid token format');
  }
};

export const waitUntilLoaded = (conditionFn: any, intervalMs: number = 100, maxAttempts = 100): Promise<any> => {
  return new Promise((resolve, reject) => {
    let attempts = 0;

    const intervalId = setInterval(() => {
      if (conditionFn()) {
        clearInterval(intervalId);

        resolve(true);
      } else {
        attempts++;

        if (attempts >= maxAttempts) {
          clearInterval(intervalId);

          reject(new Error("Condition not met within the specified time."));
        }
      }
    }, intervalMs);
  });
};

export function isJson(str: any): boolean {
  if (!str) {
    return false;
  }

  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function jsonToObj(str: any): any {
  try {
    if (isJson(str)) {
      return JSON.parse(str);
    }
  } catch (e) {
    return {};
  }
  return {};
}